import React from 'react'

function ClientPerkItem(props) {
  return (
    <article
      className={
        'description-box description-box--small ' +
        (props.additionalClass ? props.additionalClass : '')
      }
    >
      <span className="description-box__icon">{props.icon}</span>
      <h2 className="description-box__heading text--5">{props.heading}</h2>
      <p className="description-box__paragraph text--6">{props.paragraph1}</p>
      {props.paragraph2 ? (
        <p className="description-box__paragraph text--6">{props.paragraph2}</p>
      ) : null}
    </article>
  )
}

export default ClientPerkItem
