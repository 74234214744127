import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import Intro from '../../components/Intro/Intro'
import TextBlock from '../../components/TextBlock/TextBlock'
import ContainerImg from '../../components/ContainerImg/ContainerImg'
import List from '../../components/List/List'
import Goals from '../../components/Goals/Goals'
import BottomNav from '../../components/BottomNav/BottomNav'
import WorkWithUs from '../../components/WorkWithUs/WorkWithUs'
import TwoColumns from '../../components/TwoColumns/TwoColumns'
import ClientPerks from '../../components/ClientPerks/ClientPerks'

const AIDevelopment = () => {
  const discoveryProcess = [
    {
      id: 1,
      text: 'An initial call with the client, our UX Specialist, and UX writer to gather as much information as possible and understand the vision',
    },
    {
      id: 2,
      text: 'Working on an interactive prototype in Figma.',
    },
    {
      id: 3,
      text: 'Going through rounds of feedback and implementation.',
    },
  ];

  const developmentProcess = [
    {
      id: 1,
      text: 'Starting with the design, using the well-defined flow from the Figma prototype.',
    },
    {
      id: 2,
      text: 'Working in 2-week sprints.',
    },
    {
      id: 3,
      text: 'Holding update calls either weekly or bi-weekly, depending on preferences, to present the current progress and discuss it.',
    },
  ];

  const goals = [
    {
      id: 11,
      text: 'Keep clients informed but not over-communicate and take things off their plate',
    },
    {
      id: 22,
      text: 'Dedicated account manager as a single point of contact',
    },
    {
      id: 33,
      text: 'Post-Development support and collaboration',
    },
  ]
  return (
    <Layout>
      <SEO
        title="AI Apps Development "
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `ai`,
          `gpt`,
          `openai`
        ]}
      />
      <SectionHeader>
        AI Apps Development
      </SectionHeader>
      <Intro
        paragraph1="From the onset of gaining access to prominent Large Language Models like GPT-4"
        paragraph2="we have been enthusiastic about the future and its endless possibilities."
      />
      <TextBlock
        heading="Choose AI"
        paragraph1="Choose Artificial Intelligence development to make the precise decision and re-engineer the way your business works."
        paragraph2="We possess extensive experience in utilizing AI on a daily basis and in implementing systems that operate with AI under the hood."
      />
      <ContainerImg
        img="https://emersoft-public.s3.amazonaws.com/emersoft-website/ai/ai-tiny.png"
        alt="Two people using smart business cards generated by AI"
      />
      <TwoColumns
        heading="Personal AI Assistant"
        paragraph1="We crafted a web application integrating with the OpenAI Embedding API, utilizing Pinecone Vector Database and Postgres SQL to enable dynamic context. This setup facilitated predefined actions and smooth interactions with external services."
        paragraph3="The project employed OpenAI's GPT-4, alongside LangChain technology and Pinecone and Qdrant vector databases, demonstrating our capability to harmonize cutting-edge technologies for enhanced user engagement."
      />
      <Intro paragraph1="Discovery Phase Process" />
      <List data={discoveryProcess} additionalClass="list-section--bg" />{' '}
      <TextBlock
        heading="Outcomes"
        paragraph1="A clickable, interactive Figma prototype that sets the stage for the design phase, allowing us to provide accurate estimations for the development phase"
      />
      <Intro paragraph1="Development Phase Process" />
      <List data={developmentProcess}  />{' '}
      <ClientPerks />
      <Goals title={'How we work'} goalsList={goals} />
      <WorkWithUs />
      <BottomNav
        additionalClass="bottom-nav--border-top"
        heading="See More"
        firstLink="/our-work"
        firstHeading="Projects"
        secondLink="/about"
        secondHeading="About Us"
      />
    </Layout>
  )
}

export default AIDevelopment;
