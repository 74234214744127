import React from 'react'

function Goals(props) {
  const items = props.goalsList.map(item => (
    <li className="goals__item" key={item.id}>
      <span className="goals__icon">
        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <g id="checked">
              <circle
                id="Oval"
                fill="currentColor"
                cx="14.976"
                cy="14.976"
                r="14.976"
              />
              <path
                d="M11.15 21.732l7.7 7.701c6.378-1.7 11.103-7.512 11.103-14.457v-.425l-6.047-5.575L11.15 21.732z"
                id="Path"
                fill="#0AA06E"
              />
              <g id="Group" transform="translate(5.02 7.382)" fill="#FFF">
                <path
                  d="M10.335 10.949c.661.661.661 1.795 0 2.457l-1.37 1.37c-.662.661-1.796.661-2.457 0l-6-6.048c-.662-.661-.662-1.795 0-2.456l1.37-1.37c.661-.662 1.795-.662 2.457 0l6 6.047z"
                  id="Path"
                />
                <path
                  d="M15.579.508c.661-.662 1.795-.662 2.456 0l1.37 1.37c.662.661.662 1.795 0 2.457L9.013 14.68c-.662.662-1.795.662-2.457 0l-1.37-1.37c-.661-.661-.661-1.795 0-2.457L15.579.508z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </svg>
      </span>
      <p className="goals__paragraph text--6">{item.text}</p>
    </li>
  ))

  return (
    <section className="goals">
      <div className="container">
        <div className="goals__inner">
          <h2
            className="goals__heading
                        text--3
                        underline"
          >
            {props.title ? props.title : 'Goals'}
          </h2>
          <ul className="goals__list">{items}</ul>
        </div>
      </div>
    </section>
  )
}

export default Goals
